import React, { useState } from 'react'
import analyticsHelper from '../AnalyticsHelper.js'

function PillTag (props) {

  // this worked, but caused an error since it causes a change mid-render
  // functional components should only change stuff after render, with hooks
  // the onClick below called setClicked(true) instead of props.onClick, which caused a re-render
  //const [clicked, setClicked] = useState(false);
  //if (clicked) {
    //props.onClick(props.title)
  //}

  const [selected, setSelected] = useState(false)

  var colourClass = selected || props.forceSelected ? "selected" : "unselected"
  if (props.staticAppearance) {
    colourClass = "static"
  }
  const classes = "tag " + colourClass

  return (
    <div className={ classes } onClick={() => {
      props.onClick(props.title);
      setSelected(!selected)

      analyticsHelper.genEvent(
        analyticsHelper.VERB_SELECT, 
        analyticsHelper.OBJECT_TAG, 
        {
          "title":props.title,
          "type":selected
        }
      )

    }
    } >
      {props.title}
    </div>
  )
}

export default PillTag
