import React, { useState } from 'react'
import { Navigate } from 'react-router';

import analyticsHelper from '../AnalyticsHelper.js'
import Header from './Header.js'
import HTFooter  from './Footer.js'

function FullBlog (props) {

  analyticsHelper.genPageViewEvent()

  const [clicked, setClicked] = useState(false);

  const content = props.content

  if (clicked) {
    return(<Navigate to="/" push={true}/>)
  }

  return (
    <main>
      <Header />
      <div id="content_container">
        <div id="content_header">
          
          <div id="content_title">
            {content.title}
          </div>

          <div id="content_date">
            {/*{content.date_modified}*/}
          </div>

        </div>
        <div id="content_content">
          { content.content }
        </div>
      </div>
      <HTFooter />
    </main>
  )

}

export default FullBlog

/* <img id="content_back_img" alt="back_icon" src="/res/back_icon.png" onClick={() => {setClicked(true)}} /> */
/*onClick={() => {props.changeRoute("/")}}*/
