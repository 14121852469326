import React from 'react'
import { Link } from 'react-router-dom'

const menuLinks = [
  {
    path:"/",
    name:"Home"
  },
  {
    path:"/search/post",
    name:"Posts"
  },
  {
    path:"/search/faq",
    name:"FAQs"
  }
]

function Header (props) {

  const links = menuLinks.map((oneLink,index) => {
    if (props.onPath != oneLink.path) {
      return (<Link className="header_menu_link" to={oneLink.path} key={"menulink_"+index}>{oneLink.name}</Link>)
    }
  })

  return (
    <header className="ht_header">
      <div id="header_top_row">
        <Link to={"/"}>
          <img id="header_logo" alt="logo" src="/res/banner-logo.png"/>
        </Link>
        
      </div>
    </header>
  )
}

export default Header

/*

<div id="header_title_div">
          <div id="header_title">
            Home Theory
            <img id="header_canada_icon" src="/res/canada_icon.png" alt="canada_icon" />
          </div>
          <div id="header_subtitle">... make your house happen</div>
        </div>

        <div id="header_android_div">
          <div id="header_android_text">... learn on the go</div>
          <a href="https://play.google.com/store/apps/details?id=com.geocode.hometheory&hl=en" target="_blank">
            <img id="header_android_img" alt="android_icon" src="/res/android_icon.png"/>
          </a>
        </div>

<div id="header_menu_bar">
        {links}
      </div>
*/
