import React, { useState } from 'react'

import PillTag from './PillTag'

function HTFAQListRow (props) {

  const content = props.content

  const tags = content.tags.map(function (oneTag,index) {
    return (<PillTag key={"faq_tag_"+index} title={oneTag} onClick={(tag)=>{console.log(tag+" clicked")}}/>)
  })

  return (
    <div id="faq_row_container">
      <div id="faq_row_question">{"Q: "+content.question}</div>
      <div id="faq_row_answer">{"A: "+content.answer}</div>
      <div id="faq_row_tags">{tags}</div>
    </div>
  )
}

export default HTFAQListRow
