import React, { useState } from 'react'
import { Navigate } from 'react-router';

import PillTag from './PillTag'

function HTContentListRow (props) {

  const content = props.content

  const tags = content.tags.map(function (oneTag,index) {
    return (<PillTag key={index} title={oneTag} onClick={(tag)=>{console.log(tag+" clicked")}}/>)
    //return (<div className="tag" key={index}>{oneTag}</div>)
  })

  // set up state vars
  const [clicked, setClicked] = useState(false);

  // if the row is clicked view the content
  if (clicked) {
    //firebase.analytics().logEvent('to: '+content.path + " from list row");
    return (<Navigate to={"/lesson/"+content.path} push={true} />)
  }

  return (
    <div className="content_row" onClick={() => setClicked(true)}>
      <div className="content_row_top">
        <div className="content_row_title">{content.title}</div>
        {/*<div className="content_row_date">Updated {content.date_modified}</div>*/}
      </div>
      <div className="content_row_bottom">
        {tags}
      </div>
    </div>
  )
}

export default HTContentListRow

/*onClick={(event) => props.handleClick(content.url)}*/
