import memoryEncoding from './blogs/memoryEncoding.js'
import targetWeakness from './blogs/targetWeaknesses.js'
import twoPhases from './blogs/twoPhasesOfLearning.js'
import learnAtYourLeisure from './blogs/learnAtYourLeisure.js'


const blogs = [
  learnAtYourLeisure,
  memoryEncoding,
  targetWeakness,
  twoPhases
]

export default blogs