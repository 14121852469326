import React from 'react'

import MyRouter from './MyRouter.js'

class App extends React.Component {

  constructor () {
    super()
    /*this.setRoute = this.setRoute.bind(this)

    const URLParts = this.parseURL(window.location.href)
    //console.log(window.location)
    //console.log(URLParts)

    this.state = {
      URLBase: URLParts.base,
      URLRoute: URLParts.route
    }

    // listen for url change
    let that = this
    window.addEventListener('popstate', function (event) {
      const changedURLParts = that.parseURL(window.location.href)
      that.setState ({
        URLBase:changedURLParts.base,
        URLRoute:changedURLParts.route
      })
    });*/

  }

  /*parseURL (url) {
    const removeHTTP = url.split("//")
    if (removeHTTP.length > 1) {
      url = removeHTTP[1]
    }

    const URLParts = url.split("/")
    const base = URLParts[0]

    let route = ""
    if (URLParts.length > 1) {
      for (var i = 1; i < URLParts.length; i++) {
        route = route+"/"+URLParts[i]
      }
    }

    return {
      base:base,
      route:route
    }
  }*/

  /*const onClick = (e) => {
        const aNewTab = e.metaKey || e.ctrlKey;
        const anExternalLink = props.href.startsWith('http');

        if (!aNewTab && !anExternalLink) {
            e.preventDefault();
            history.push(props.href);
        }
    };*/
  /*updateURL (newURL) {
    history.pushState(null, '', newURL);
    window.dispatchEvent(new Event('popstate')); // this turns the popstate listener into a every change listener
  }

  setRoute (route) {
    this.setState (function (prevState) {
      return {
        URLRoute:route
      }
    })
    this.updateURL(route)
  }*/

  render () {
    return (
      <MyRouter />
    )
  }
}

export default App
