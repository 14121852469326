
import posthog from 'posthog-js'

class AnalyticsHelper {
  constructor () {
    if (!AnalyticsHelper.instance) {
      //if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
      posthog.init('phc_jBycJOIo9Ah15z4O4ROTob4Kcf9p8MuLSmxDvl1HJsu', 
      { 
        api_host: 'https://app.posthog.com',
        autocapture: false 
      })
      //}
      AnalyticsHelper.instance = this
    }
    // Initialize object
    return AnalyticsHelper.instance
  }

  OBJECT_BLOG = "blog"
  OBJECT_TAG = "tag"
  VERB_SELECT = "selected"

  // event is one of the constants above
  // type is
  genEvent (verb, object, props) {
    posthog.capture(verb +" "+ object, props)
  }

  genPageViewEvent () {
    posthog.capture('$pageview')
  }

}

const instance = new AnalyticsHelper()
Object.freeze(instance)

export default instance
