import React  from 'react'; // need this to use jsx (the html inside the array. noramally that would be in a string)
const value = {
  path:"memoryEncoding",
  title:"Memory Encoding",
  date_modified:"Nov 2023",
  date_created:"Oct 2023",
  synopsis:"Everyone knows you need memory to learn; but how memories are formed and retained is knowledge not-so prevalent. In this writing we explore how it happens, how to take advantage of the mechanics of memory, and how to apply these insights to become a better learner.",
  content:
[
<p align="justify" key="1">
Memory is the heart and soul of learning. It allows us to consider what we’ve experienced in the past, to better understand the present. Memory formation-and-use is composed of three parts: encoding, storage, and retrieval; understanding all three phases allows us to optimize our learning, but the first stage – encoding – is the most important when it comes to the retention of knowledge.
</p>
,
<p align="justify" key="2">
Memory encoding is the process by-which perceived information is converted into a form that can be stored in our long-term memory. You can think of encoding as happening in 4 main phases: 
</p>
,
<ul>
    <li><b>Sensory encoding</b>: The receiving of information from any of the 5 senses, and temporary storage of that information for later processing. </li>
    <li><b>Attention</b>: The first level of information filtering. Anything that’s not attention-grabbing or particularly relevant is discarded.</li>
    <li><b>Perceptual Encoding</b>: Information is processed for familiar patterns and associations. For example, recognizing the signs of happiness on a face; or being able to distinguish that a sound you are hearing is singing.</li>
    <li><b>Semantic Encoding</b>: The data is analyzed for value and meaning. This is where our brains relate the new information to our existing knowledge, so that new conclusions can be formed. For example: seeing many happy faces on the people leaving a theater may cause you to conclude that the movie they watched is a good one.</li>
</ul>
,
<p align="justify" key="3">
Needless to say, encoding, and in particular semantic encoding, is the meat-and-potatoes of learning. Without it, new information would be next to meaningless.
</p>
,
<p align="justify" key="4">
Whenever we engage with new information in an active manner, we are encoding it; and hence strengthening the sticking-power of that information. The more time our brain spends processing information, and the more ways it’s presented with this information (e.g. visual vs. acoustic), the more likely it’s going to transfer it to long-term memory; and the easier it will be to maintain it and access it from long-term memory. Once our brains have decided the information is important enough, it begins the transfer to LTM through a process called memory consolidation, which begins during encoding.
</p>
,
<p align="justify" key="5">
Put simply, the effectiveness of our learning will depend directly on how well encoding is performed. Luckily, our ability to encode is not limited only by genetics: we are able to improve it through optimized technique and practice.
</p>
,
<p align="justify" key="6">
First of all, you want to be sure you’re working in an environment, and state-of-mind, that supports encoding:
</p>
,
<ul>
    <li><b>Limit Distractions</b>: Pay attention to what you are learning. If you don’t give the content the attention it deserves, it will be hard for your brain to truly process the information. It takes time to ponder and draw meaning. Interruptions will prevent your brain from connecting the content to your past knowledge, limiting encoding effectiveness.  </li>
    <li><b>Mental Health</b>: Both stress and depression can impact encoding in a negative way. They make it difficult to focus and think clearly.</li>
    <li><b>Sleep</b>:  Not only does sleep deprivation make it difficult to focus, but sleep-time is also when our brains consolidate and organize new information we acquired during the day. If this process does not have the opportunity to happen, many of our memories won’t get transferred to long-term storage.</li>
</ul>
,
<p align="justify" key="7">
After providing yourself with the best conditions for success, it’s a good idea to start focusing on the strategies and techniques used to power your encoding:
</p>
,
<ul>
    <li><b>Depth of Processing</b>: The depth of processing during encoding is crucial. Shallow processing, which involves minimal analysis or engagement with the information (e.g., simply skimming over text), often leads to weaker memory traces. On the other hand, deep processing, where you engage with and think deeply about the material, enhances memory. The more time you spend thinking about something, the more it’s going to permeate your brain.</li>
    <li><b>Elaboration</b>: High-quality encoding often involves elaborative processing, where you make connections between new information and your existing knowledge and experiences. This creates a web of associations that makes the information more memorable.</li>
    <li><b>Repetition</b>:  Repeated exposure or rehearsal of information during encoding can also contribute to higher quality encoding. Repetition helps strengthen the memory trace and facilitates the transfer of information into long-term memory. Of course, you must deeply understand what you are repeating; repetition of surface level information is not helpful.</li>
    <li><b>Variety</b>:  Consume and recall the information in multiple ways. You can consume information both visually and acoustically; and you can attempt to recall the information for different purposes. E.g. testing yourself or teaching it to someone else.</li>
</ul>
,
<p align="justify" key="8">
There are also some poor encoding habits that would be best to avoid:
</p>
,
<ul>
    <li><b>Multitasking</b>: Avoid trying to encode information while multitasking. Dividing your attention between multiple tasks can lead to shallow encoding and reduced memory retention. It’s very difficult to jump back to exactly where you left off, on the path to understanding, and we usually only have so much time to complete the journey.</li>
    <li><b>Cramming/Overloading</b>: Avoid last-minute, intensive study sessions. Cramming does not allow for effective encoding and often results in forgetfulness shortly after the learning session. When too much information is shoved into your brain at once, there isn’t enough time or space to process it effectively. It will be quickly forgotten; and while retained, it will be usable in only a limited number of contexts.</li>
    <li><b>Skipping Breaks</b>: Prolonged study sessions without breaks can diminish the quality of encoding. Just like all the muscles in your body, your brain needs a specific chemical balance to operate properly. Let it rest and recharge – take regular breaks to recoup your cognitive resources.</li>
    <li><b>Mindless Repetition</b>: Repeating information without understanding likely won’t lead to effective encoding. If there is no value in the information being reviewed, it becomes very difficult to convince your brain it’s worth retaining. Additionally, the information will only be usable in the context it was learned: without real understanding, you can’t extrapolate it to other situations. Avoid rote memorization without deeper comprehension – it is not an efficient use of time.</li>
</ul>
,
<p align="justify" key="9">
If information has been encoded well, using these or similar techniques, it will be committed to LTM in a more timely and lasting manner. In addition, even though all memories in our LTM need to be maintained/refreshed (i.e. re-encoded), ones that were encoding in a quality manner will require less work to maintain, and are therefore less susceptible to errors introduced during re-encoding.
</p>
,
<p align="justify" key="10">
However tempting it may be to simply skim through a book or watch a couple videos on a topic, just to be able to say you put in the time, remember: it’s always going to be worth it to put in the effort and encode the information well. Quality encoding means more value from the content, less work to commit it to memory, less work to maintain it in memory, and greater success recalling it.    
</p>
],
  tags:["memory","technique","physiology"]
}

export default value