import React  from 'react'; // need this to use jsx (the html inside the array. noramally that would be in a string)
const value = {
  path:"targetWeaknesses",
  title:"Learn Faster by Targeting Weaknesses",
  date_modified:"Sept 2023",
  date_created:"Sept 2023",
  synopsis:"In this busy world, it can be difficult to find the time to learn something new. One -- often-under-looked -- way to make it easier to find the time is to learn as efficiently as possible. By implementing a strategy around targeting your weakness, you effectively sacrifice comfort for speed. While it can be a trying approach, not suited for everyone or every situation, it’s a go-to strategy for busy people dedicated to making it happen.",
  content:
[
<p align="justify" key="1">
Whether it was while starting a new hobby, studying for school, or learning a new skill to add to the resume, most of us have neglected part of our subject-at-hand, at some point. Since it’s natural for us to avoid what’s not easy (failure makes us feel bad), we tend to neglect because we believe we’re going to be bad at whatever it is we’re neglecting. This is why we keep delaying our renovation projects, and pushing our next piano practice to “tomorrow” – we aren’t lazy, we’re just avoiding failure. However, these sorts of struggles are essential to grow. Challenges keep life interesting, and the ones you’ve been avoiding are ripe for the picking. Just as there are obvious ways to progress as individuals, there are obvious ways to further advance the subjects we are learning: by braving the struggle of improving what we are bad at – by targeting our weaknesses.
</p>
,
<p align="justify" key="2">
A lot of time can be wasted saying “I’ll get to it eventually”. One day, two days, five, a year … time really flies when you’re putting stuff off. Wasted time is obvious when it’s spent cruising social media, but there is also some waste when you are learning at a lower rate of absorption than you could be. The first time you dip your toe in a pool of knowledge, there are seemingly unlimited things to learn. The more you learn, the less there is left to learn; and since we can never run out of things to learn, the longer it takes to learn anything. The benefit-to-effort ratio of learning is always highest at the beginning, and gradually reduces as more content is processed. For example, for some musical instruments it’s completely reasonable to not be able to produce any sound without any before-hand instruction. At this point, it might take you 5-10 minutes of instruction and experimentation to figure out how to do so; and when evaluated on an objective scale, going from no-sound to some-sound is a dramatic improvement. Contrast that with practicing the instrument for years in order to make it sound beautiful – which, in physical terms, is a much smaller progression.
</p>
,

<p align="justify" key="3">
Not only does avoiding a topic limit your knowledge on it, specifically, but it can also hinder your grasp of dependent or related topics. Consider all the knowledge in your noggin as a forest; where each tree represents a subject, trunks can be thought of as the fundamentals, and branches represent sub-topics – where the most advanced topics are the most slender branches. Not understanding the trunk or a hefty branch will severely disrupt your understanding of the entire subject. By neglecting to learn a specific aspect of your subject you could be breaking off a branch that’s holding up many smaller branches, or, you could be cutting down the entire tree! People will often try to fill in the resulting gap by spending extra time on nearby concepts, pruning and enticing, to fill the void; but this is an inefficient means, and such an approach can never deliver the same results as focusing on the concept directly. Let the trees grow full, so the forest can flourish.
</p>
,
<p align="justify" key="4">
Bringing these ideas together results in a pretty interesting study method to experiment with. Everytime you sit down for a study session, instead of working on an area you enjoy, focus on the branch that has the most growing to do. The idea is to keep focusing on this area until it’s no longer your biggest weakness. This may mean for 2 or 3 sessions, it may mean you’re chugging away at it for a month. Keep going until it’s no longer a weakness, and then shift to your new biggest weakness. This method ensures you are always working in the most fertile soil, where the biggest gains can be made. The downside, of course, is that you are always the most uncomfortable you could possibly be. But, that’s okay; a little exposure to discomfort is never a bad thing.
</p>
],
  tags:["strategy","technique"]
}

export default value