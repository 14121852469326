import React  from 'react'; // need this to use jsx (the html inside the array. noramally that would be in a string)
const value = {
  path:"twoPhases",
  title:"The Two Essential Phases of Learning: Theory and Practise",
  date_modified:"May 2023",
  date_created:"June 2023",
  synopsis:"To learn fast and smart, you must have a plan; and, if nothing else comes to mind, you can always fall-back on the basics: theory and practice. It’s tempting to do one or the other, but utilizing both is where real learning progress is made. Potential implementations of this basic approach are limitless, and can be adapted and adopted to cover all skill levels of all topics, as you progress through your learnings.",
  content:
[
<p align="justify" key="1">
Do you remember during school, when the adults in your life could not be more sure that going to class and doing your homework was a recipe for world domination? While the emphasis was usually placed – misguidedly – on getting good grades, it turns out the education system is focused on the lecture-homework duo for good reason: it provides a respectable dose of both theory and practice. As it happens, theory is needed as a starting point, and practice is needed to truly understand the theory. When utilized individually, efforts become inefficient; but when employed together, learning potential becomes limitless.
</p>
,
<p align="justify" key="2">
In my experience, it’s not so bad getting around to one of the two; but accomplishing both is always a hassle – and I’m not sure why. I have two hypotheses: there is always some factor prodding me to consume theory or practice, which makes it easier to start; and, by the time I’m finished whichever phase I started with (it should always be theory, but it seems that’s not always the case) I’ve lost motivation to perform the second. In any case, the purpose of this writing is to highlight how easy it is to accomplish one, shrug off the second, and why it’s so important to persevere through both.
</p>
,

<p align="justify" key="3">
When you’ve got a busy life and are tetris-ing your time to fit in some learning, the last thing you want is to be wasting it by practicing inefficiently – or practicing the wrong things entirely. To avoid this, one should study the theory of a subject first, for clarity on what should be practiced. It’s easy to think of this approach as a 1-2 process, where a learner studies the theory and then does the practice, but I would recommend otherwise. Instead, it should be viewed as a continual cycling between the two: theory, practice, repeat. By minimizing the size of the cycles, and thereby avoiding cramming, you give your brain the time and space needed to absorb every detail. Additionally, when you revisit the theory at high frequency, you give yourself a chance to correct-course on how (and what) you’re practicing. The new dose of theory sparks ideas on how practice can be optimized, further increasing the efficiency and effectiveness of your learning.
</p>
,
<p align="justify" key="4">
Theory is nice because it’s informative and simple (in concept). There’s not a very high chance that we’ll simply fail at consuming information – unlike solving a practice problem or practice question. The idea of failure can be quite frightening, which is why theory is the “nicer” of the two. Unfortunately, the pleasantness and simplicity of consuming theory is also the reason for its individual inadequacy: it’s hard to remember and truly comprehend things we don’t struggle over. Hence the necessity of practice – and all of its discomforts.
</p>
,
<p align="justify" key="5">
One pitfall I find myself impeded by often is what I refer to as “the theory trap”. Put simply: I keep consuming theory far past when I should’ve switched to practice. After falling into a routine, it can be difficult to escape the comforting embrace of being “productive”, when practice, and its accompanying mistakes, are lurking nearby. In reality, that sense of productivity is a lie, and efforts have likely long-since become inefficient. That false sense of productivity – coupled with latent avoidance of practice-induced mistakes – can make it difficult to switch gears. I’ve concluded that in order to optimize the time-spend in each phase, one must remain vigilant. As soon as you know enough to start experimenting, making mistakes, and working through solutions, it’s time to start putting that theory to the test.
</p>
,
<p align="justify" key="6">
Another mistake I catch myself making frequently is failing to progress to more challenging practice. If you’re no longer making mistakes, you’re no longer learning. Unfortunately, that means the discomforts of practice are unavoidable and necessary: you must keep up-ing the difficulty of your practice to continue to learn. Therefore, to be an effective learner, it’s important to become comfortable with being uncomfortable. When you’ve achieved confidence in a particular skill, it can be tempting to revel in your successful summit. But don’t linger for too long: rest, resupply, and find a taller mountain.
</p>
,
<p align="justify" key="7">
Consume the theory (but not for too long); test your understanding via practice (and make it challenging); and repeat. Think of this process as a two-step flywheel: use the momentum of the previous cycle to launch into the next. Things will start progressing quickly, efficiently, and with purpose.
</p>
],
  tags:["strategy"]
}

export default value