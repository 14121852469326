import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom"

import Header from './Header.js'
import HTFooter  from './Footer.js'
import SearchBar from './SearchBar.js'
import HTContentListRow from './HTContentListRow.js'
import HTFAQListRow from './HTFAQListRow.js'

import lessonsArray from '../content/blogs.js'
import faqsArray from '../content/blogs.js'

const contentArray = lessonsArray.concat(faqsArray)

function HTSearchPage (props) {

  let location = useLocation();
  //let navigate = useNavigate();
  //let params = useParams();

  const parseSearchKey = (queryParams) => {
    let index = queryParams.indexOf("key") + 4
    let key = queryParams.substring(index)
    key = key.replaceAll("%20", " ")
    return key
  }

  const onSearch = (searchText) => {
    if (searchText === "") {
      setFilteredLessons(lessonsArray)
      setFilteredFAQs(faqsArray)
    } else {
      let filteredLessons = filterLessons(lessonsArray,searchText)
      setFilteredLessons(filteredLessons)
      let filteredFAQs = filterFAQs(faqsArray,searchText)
      setFilteredFAQs(filteredFAQs)
    }
  }

  const buildList = () => {

    if (filteredLessons.length == 0 && filteredFAQs.length == 0) {
      return (<div id="no_results">* No Results *</div>)
    }

    let list = []
    //console.log(this.state.filtered)
    if (includeFAQs) {
      filteredFAQs.forEach(function (oneFAQ,index) {
        list.push(<HTFAQListRow content={oneFAQ} key={"faq_"+oneFAQ.uid} />)
      })
    }
    if (includeLessons) {
      filteredLessons.forEach(function (oneContent,index) {
        list.push(<HTContentListRow content={oneContent} key={"lesson_"+oneContent.path} />)
      })
    }
    return (
      <div id="row-container">
        {list}
      </div>
    )
  }

  // array of objects, string
  const filterLessons = (source,key) => {
    var filteredList = []
    source.forEach((item, i) => {
      if (item.title.toLowerCase().includes(key.toLowerCase())) {
        filteredList.push(item)
        return
      }

      let added = false // to avoid duplicates
      item.tags.forEach((oneTag, i) => {
        if (added) {return}
        if (oneTag.toLowerCase().includes(key.toLowerCase())) {
          filteredList.push(item)
          added = true
          return
        }
      });
    });
    return filteredList
  }

  // array of objects, string
  const filterFAQs = (source,key) => {
    var filteredList = []
    source.forEach((item, i) => {

      if (item.question.toLowerCase().includes(key.toLowerCase())) {
        filteredList.push(item)
        return
      }

      let added = false // to avoid duplicates
      item.tags.forEach((oneTag, i) => {
        if (added) {return}
        if (oneTag.toLowerCase().includes(key.toLowerCase())) {
          filteredList.push(item)
          added = true
          return
        }
      });

      if (added) {
        return
      }

    });
    //console.log("list "+JSON.stringify(filteredList))
    return filteredList
  }

  const getDefaultIncludeLessonsValue = () => {
    return props.includeLessons != null ? props.includeLessons : true
  }

  const getDefaultIncludeFAQsValue = () => {
    return props.includeFAQs != null ? props.includeFAQs : true
  }

  // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
  useEffect(() => {
    onSearch(initKey)
  }, []);

  // handle prop change
  useEffect( () => {
    setIncludeLessons(getDefaultIncludeLessonsValue())
  }, [props.includeLessons])
  useEffect( () => {
    setIncludeFAQs(getDefaultIncludeFAQsValue())
  }, [props.includeFAQs])
  useEffect( () => {
    setInitKey(parseSearchKey(location.search))
  }, [location.search])

  const [includeLessons, setIncludeLessons] = useState(getDefaultIncludeLessonsValue());
  const [includeFAQs, setIncludeFAQs] = useState(getDefaultIncludeFAQsValue())
  const [initKey, setInitKey] = useState(parseSearchKey(location.search))
  const [filteredLessons, setFilteredLessons] = useState(lessonsArray)
  const [filteredFAQs, setFilteredFAQs] = useState(faqsArray)

  return (
    <main>
      <Header HTTitle="Home Theory" onPath="/search"/>
      <SearchBar onSearch={onSearch} initKey={initKey} />
      {buildList()}
      <HTFooter />
    </main>
  )

}

export default HTSearchPage
