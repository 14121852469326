import React from 'react'

class SearchBar extends React.Component {

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this) // bind allows this to be referenced in handleChange
    this.keyPress = this.keyPress.bind(this)
    this.state = {searchValue: ''};
  }

  componentDidMount () {
    this.setState({searchValue: this.props.initKey || ""})
  }

  handleChange (event) {
    this.setState({searchValue:event.target.value})
  }

  keyPress (e) {
    if (e.key === "Enter") {
      this.props.onSearch(this.state.searchValue)
      return false;
    }
    return true;
  }

  render () {
    return (
      <div id ="search_bar">
        <input id="search_bar_input" type="text" placeholder="what do you want to learn about?" value={this.state.searchValue} onChange={this.handleChange} onKeyPress={this.keyPress} autoComplete="off"/>
        <button id="search_bar_submit" onClick={(event) => this.props.onSearch(this.state.searchValue)}><img id="search_icon" src="/res/search_icon.png"/></button>
      </div>
    )
  }

}

export default SearchBar
