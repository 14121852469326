import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router';
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom"

import analyticsHelper from '../AnalyticsHelper.js'

import Header from './Header.js'
import HTFooter  from './Footer.js'
import PillTag from './PillTag.js'
import BlogListRow from './BlogListRow.js'

import blogsArray from '../content/blogs.js'

const listToFilterBy = []

function Landing (props) {

  analyticsHelper.genPageViewEvent()

  const [filteredBlogs, setFilteredBlogs] = useState(blogsArray)

  const [showFilter, setShowFilter] = useState(true) // default this to false if/when you add a button to show/hide the filter

  const changeFilter = (filterText) => {
    
    const index = listToFilterBy.indexOf(filterText)
    if (index > -1) {
      // we are removing from the filter
      listToFilterBy.splice(index, 1); // 2nd parameter means remove one item only
      filter(listToFilterBy)
      return
    }

    // we are adding to the filter
    listToFilterBy.push(filterText)

    filter(listToFilterBy)
  }

  const filter = (list) => {

    if (list.length == 0) {
      setFilteredBlogs(blogsArray)
      return
    }

    const filteredList = []
    blogsArray.forEach((item, i) => {

      let added = false // to avoid duplicates
      item.tags.forEach((oneTag, i) => {
        if (added) {return}
        if (list.includes(oneTag)) {
          filteredList.push(item)
          added = true
          return
        }
      });

    });

    setFilteredBlogs(filteredList)
  }

  const buildList = () => {

    if (filteredBlogs.length == 0) {
      return (<div id="no_results">* No Results *</div>)
    }

    let list = []

    filteredBlogs.forEach(function (oneContent,index) {
      list.push(<BlogListRow content={oneContent} key={"blog_"+oneContent.path} />)
    })

    return (
      <div id="row-container">
        {list}
      </div>
    )
  }

  const buildTags = () => {

    // create the list as an object initially, to prevent duplicates
    //let that = this
    let pills = {}
    blogsArray.map(function (content,contentIndex) {
      content.tags.map(function (tag,tagIndex) {
        pills[tag] = <PillTag title={tag} onClick={(tag) => changeFilter(tag)} key={"tag_"+contentIndex+tagIndex} />
      })
    })

    // convert object to array so you can use it as jsx child
    let pillsArray = Object.values(pills)

    return (
      <div id="pill-container" className={ showFilter ? "show": "hide" }>
        { pillsArray }
      </div>
    )
  }

  return (
    <main>
      <Header HTTitle="Home Theory" onPath="/"/>
      
      { buildTags() }
      { buildList() }
      <HTFooter />
    </main>

  )
}

/*

<div onClick={() => setShowFilter(!showFilter)}>click me</div>

handleClick={that.selectContent}

{one != null ? <FullBlog content={one} changeRoute={this.props.changeRoute} /> : this.buildList(this)}

<HTLanding changeRoute={this.setRoute} route={this.state.URLRoute}/>

<div id="landing_search_container">
          <SearchBar onSearch={onSearch} />
        </div>

*/

/*if (this.state.searchKey != "") {
      return (<Navigate to={"search?key="+this.state.searchKey} push={true} />)
    }*/

export default Landing
