import React  from 'react';
const value = {
  path:"learnAtYourLeisure",
  title:"Learn at Your Leisure",
  date_modified:"Jan 2024",
  date_created:"Jan 2024",
  synopsis:"While it’s always important to optimize how we learn, as it turns out, putting some thought into what we learn deserves some attention, as well. The positive effects of actually enjoying the process of learning – on our learning – should never be underestimated.",
  content:
[
<p align="justify" key="1">
The simple fact of the matter is that we learn better when we are enjoying ourselves. Our modern education systems seem to be lacking this insight, but it has been the case for a long time. Not only does the word "school" originate from the Greek word for leisure, <i>schole</i>; but, even Plato – whose musings have had a significant influence on our modern cultures – thought the sentiment worthy of documentation (1). Knowing how important enjoyment is to the learning process adds some clarity as to why there are so many kids struggling to achieve good grades in mundane subjects; and so many parents wasting money on music lessons that never seem to amount to anything. It’s difficult to enjoy what’s been forced upon us, and it’s difficult to learn when we aren’t enjoying.
</p>
,
<p align="justify" key="2">
Now the question becomes, how exactly do we get to a place where we can enjoy learning? And the answer is rather simple: the key is to be studying something <i>you</i> find meaningful. This may be difficult to accept, given how much weight our cultures put behind the importance of conforming to the education system, but it becomes more evident once you take the decision making into your own hands. The simple act of deciding for yourself can have an incredible impact on your attitude toward learning, which is handy, because a positive mindset goes a long way when navigating the challenges of learning something new. If you want to flip the switch on learning, you need to take control.
</p>
,

<p align="justify" key="3">
It's worth spending a little more time discussing the link between learning and enjoyment. To take full advantage of the benefits, the relationship needs to be thoroughly understood. At a biological level, the root of learning-caused-enjoyment is our evolved love of realization. When we obtain novel information, we get excited at the prospect of utilizing this new-found knowledge to improve our lives – in whatever ways possible. When we are given enough time and space to fully ponder and conclude, our bodies reward us with a release of endorphins – an encouragement to keep learning.
</p>
,
<p align="justify" key="4">
Of course, getting to the enjoyable parts of life usually requires struggle, and learning is no different. In order to learn we must first acknowledge that we do not know; and just like when we are told we are wrong, not knowing can be a distressing feeling. Every time you learn something new, part of your pre-existing knowledge-base must be destroyed, which, when happens in excess, can be rather uncomfortable. This is one of the factors that contribute to poor engagement in schools: for many students, the rate of content exposure is too high, and they end-up feeling as though they know nothing.</p>
,
<p align="justify" key="5">
So, in order to reap the enjoyment and growth of learning, we must first go through some pain (admitting we don't know); even if we are studying what we find meaningful. And, in theory, to maximize the pain is to maximize the learning. It’s quite easy to let one of these incentivising forces be taken to an extreme; but, if we want to maintain efficiency, we must prioritize keeping them both balanced. Attempting to minimize the pain of “not knowing” by studying something easy results in no meaningful learning/growth/enjoyment; and trying to learn too much, too fast, results in self-doubt and abandonment. If it’s too easy you’ll get bored; if it’s too hard you’ll get frustrated. In addition to striking a balance, be wary of learning that seems like the right amount of challenge, but doesn’t result in a sense of pride upon achieving useful understanding. In these cases, you may be studying concepts that you simply don’t find meaningful. In other words: you might not be learning at your leisure.</p>
,
<ol>
    <li><i>Because a freeman ought not to be a slave in the acquisition of knowledge of any kind. Bodily exercise, when compulsory, does no harm to the body; but knowledge which is acquired under compulsion obtains no hold on the mind.</i> - Plato, Republic, Book 7</li>
</ol>
],
  tags:["strategy"]
}

export default value