import React from 'react'

import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom'

// import * as ROUTES from '../../constants/routes';
// e.g. ROUTES.LANDING

import Landing from './components/LandingPage.js'
import HTSearchPage from './components/HTSearchPage.js'
import FullBlog from './components/FullBlog.js'

import blogsArray from './content/blogs.js'

const contentRoutes = blogsArray.map((content) => {
  let path = "/blog/"+content.path
  return(<Route key={content.path+"_route"} path={path} exact element={ <FullBlog content={content} /> }  />)   
})

function MyRouter (props) {

  return (
    <Router>
    <Routes>
      <Route exact path="/" element={ <Landing/> } />
      <Route path="/search/post" element={<HTSearchPage includeFAQs={false} /> } />
      <Route path="/search/faq" element={<HTSearchPage includeLessons={false} /> } />
      <Route path="/search" element={ <HTSearchPage/> } />
      { contentRoutes }
      
    </Routes>
    </Router>
  )
}

export default MyRouter
